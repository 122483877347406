import request from "../plugins/axios";

export function getTagList(params) {
  return request({
    url: '/contactMessageBatchSend/contactTag',
    method: 'GET',
    params
  })
}

export function getEmployeeList(params) {
  return request({
    url: '/contactMessageBatchSend/employeeList',
    method: 'GET',
    params
  })
}

export function getRoomOwnerList(params) {
  return request({
    url: '/roomMessageBatchSend/roomOwner',
    method: 'GET',
    params
  })
}

export function getFollowStatusList(params) {
  return request({
    url: '/workbench/followStageIndex',
    method: 'GET',
    params
  })
}
